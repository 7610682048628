html {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(36,36,36,1) 79%, rgba(79,79,79,1) 100%);
}

.App {
  text-align: center;
}

.header-text {
  font-size: calc(24px + 10vmin);
  color: #999;
  margin-top: 5vh;
  padding: 8px;
  z-index: 2;
}

.description-text {
  font-size: calc(10px + 1vmin);
  color: #999;
  margin-top: 10vh;
  padding: 8px;
  z-index: 2;
  overflow-wrap: break-word;
}

.generated-password-text {
  font-size: calc(14px + 2vmin);
  color: #000;
  margin-top: 15vh;
  padding: 8px;
  z-index: 2;
  cursor: pointer;
}

.chaos-star {
  min-width: 60vw;
  max-width: 60vh;
  pointer-events: none;
  position: absolute;
  top: -35vh;
  z-index: -1;
  overflow: hidden;
}

.page-counter {
  color: #0FFF50;
  font-size: calc(14px + 2vmin);
  padding: 8px;
  z-index: 2;
  cursor: pointer;
}

.regenerate-button {
  font-size: calc(14px + 2vmin);
  color: #ccc;
  padding: 8px;
  z-index: 2;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .chaos-star {
    animation: chaos-star-spin infinite 60s linear;
  }
}

.chaos-star-container {
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
}

@keyframes chaos-star-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlighter {
    animation: fadeoutBg 1s; /***Transition delay 1s fadeout is class***/
    -moz-animation: fadeoutBg 1s; /* Firefox */
    -webkit-animation: fadeoutBg 1s; /* Safari and Chrome */
    -o-animation: fadeoutBg 1s; /* Opera */
}

@keyframes fadeoutBg {
    from { background-color: #888; } /** from color **/
    to { background-color: white; } /** to color **/
}

@-moz-keyframes fadeoutBg { /* Firefox */
    from { background-color: #888; }
    to { background-color: white; }
}

@-webkit-keyframes fadeoutBg { /* Safari and Chrome */
    from { background-color: #888; }
    to { background-color: white; }
}

@-o-keyframes fadeoutBg { /* Opera */
    from { background-color: #888; }
    to { background-color: white; }
}
